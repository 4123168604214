import {
  Box,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  styled,
  CSSObject,
  Divider,
  Typography,
  Theme,
} from '@mui/material';
import ConversationsDefaultIcon from './icons/conversations-default.svg';
import ConversationsActiveIcon from './icons/conversations-active.svg';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import { FC, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './icons/Logo-Horizontal.svg?url';
import useLocalStorageState from 'use-local-storage-state';
import OverviewDefaultIcon from './icons/overview-default.svg';
import OverviewActiveIcon from './icons/overview-active.svg';
import WorkflowDefaultIcon from './icons/workflow-default.svg';
import WorkflowActiveIcon from './icons/workflow-active.svg';
import AssistantDefaultIcon from './icons/assistant-default.svg';
import AssistantActiveIcon from './icons/assistant-active.svg';
import PhoneDefaultIcon from './icons/phone-default.svg';
import PhoneActiveIcon from './icons/phone-active.svg';
import WalletDefaultIcon from './icons/wallet-default.svg';
import WalletActiveIcon from './icons/wallet-active.svg';
import SettingsDefaultIcon from './icons/settings-default.svg';
import SettingsActiveIcon from './icons/settings-active.svg';
import ActionsDefaultIcon from './icons/actions-default.svg';
import ActionsActiveIcon from './icons/actions-active.svg';
import ContactsDefaultIcon from './icons/contacts-default.svg';
import ContactsActiveIcon from './icons/contacts-active.svg';

import LogoutIcon from './icons/logout-default.svg';

const drawerWidth = 220;

const items = [
  {
    title: 'Overview',
    Icon: OverviewDefaultIcon as unknown as FC,
    ActiveIcon: OverviewActiveIcon as unknown as FC,
    url: '/overview',
  },
  {
    title: 'Assistants',
    Icon: AssistantDefaultIcon as unknown as FC,
    ActiveIcon: AssistantActiveIcon as unknown as FC,
    url: '/assistants',
  },
  {
    title: 'Conversations',
    Icon: ConversationsDefaultIcon as unknown as FC,
    ActiveIcon: ConversationsActiveIcon as unknown as FC,
    url: '/conversations',
  },
  {
    title: 'Custom Actions',
    Icon: ActionsDefaultIcon as unknown as FC,
    ActiveIcon: ActionsActiveIcon as unknown as FC,
    url: '/actions',
  },
  {
    title: 'Workflows',
    Icon: WorkflowDefaultIcon as unknown as FC,
    ActiveIcon: WorkflowActiveIcon as unknown as FC,
    url: '/workflows/',
  },
  {
    title: 'Contacts',
    Icon: ContactsDefaultIcon as unknown as FC,
    ActiveIcon: ContactsActiveIcon as unknown as FC,
    url: '/contacts',
  },
  {
    title: 'Numbers',
    Icon: PhoneDefaultIcon as unknown as FC,
    ActiveIcon: PhoneActiveIcon as unknown as FC,
    url: '/numbers/',
  },
  {
    title: 'Wallet',
    Icon: WalletDefaultIcon as unknown as FC,
    ActiveIcon: WalletActiveIcon as unknown as FC,
    url: '/wallet/',
  },
  {
    title: 'Settings',
    Icon: SettingsDefaultIcon as unknown as FC,
    ActiveIcon: SettingsActiveIcon as unknown as FC,
    url: '/settings',
  },
];

interface IProps {
  children: ReactNode;
}

export default function Layout({ children }: IProps) {
  const [open, setOpen] = useLocalStorageState('isDrawerOpen', {
    defaultValue: false,
  });

  const logout = () => {
    localStorage.clear();
    window.location.href = 'https://contacti.ai';
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();
  return (
    <Box display="flex">
      <Drawer
        variant="permanent"
        open={open}
      >
        <LogoImg
          src={Logo}
          height={36}
        />
        <List sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          {items.map((item) => (
            <CustomListItem
              key={item.title}
              open={open}
              title={item.title}
              onClick={() => navigate(item.url)}
              active={pathname.startsWith(item.url)}
              icon={
                pathname.startsWith(item.url) ? (
                  <item.ActiveIcon />
                ) : (
                  <item.Icon />
                )
              }
            />
          ))}
          <CustomListItem
            mtAuto
            title="Logout"
            icon={<LogoutIcon />}
            open={open}
            onClick={logout}
            active={false}
          />
          <Divider />
          <CustomListItem
            title="Collapse"
            onClick={() => setOpen((p) => !p)}
            open={open}
            active={false}
            icon={
              <DoubleArrowIcon
                sx={[
                  (theme) => ({
                    fill: theme.palette.primary.light,
                    transition: 'transform .25s ease-out',
                  }),
                  open
                    ? {
                        transform: 'rotate(180deg)',
                      }
                    : {
                        transform: 'rotate(0deg)',
                      },
                ]}
              />
            }
          />
        </List>
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text) => (
            <ListItem key={text} disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DialpadIcon />
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3 }}
      >
        {children}
      </Box>
    </Box>
  );
}

interface ICustomListItemProps {
  open: boolean;
  active: boolean;
  onClick: () => void;
  mtAuto?: boolean;
  title: string;
  icon: ReactNode;
}

function CustomListItem({
  open,
  title,
  active,
  onClick,
  mtAuto,
  icon,
}: ICustomListItemProps) {
  return (
    <ListItem
      disablePadding
      sx={mtAuto ? { mt: 'auto' } : {}}
    >
      <ListItemButton
        onClick={onClick}
        sx={[
          {
            minHeight: 48,
            px: 2.5,
          },
          open
            ? {
                justifyContent: 'initial',
              }
            : {
                justifyContent: 'center',
              },
        ]}
      >
        <ListItemIcon
          sx={[
            {
              minWidth: 0,
              justifyContent: 'center',
            },
            open
              ? {
                  mr: 2,
                }
              : {
                  mr: 'auto',
                },
          ]}
        >
          {icon}
        </ListItemIcon>
        <Typography
          sx={[
            {
              fontSize: 14,
              display: open ? 'block' : 'none',
              fontWeight: active ? 600 : 500,
            },
            (theme) => ({
              color: active
                ? theme.palette.primary.main
                : theme.palette.grey[700],
            }),
          ]}
        >
          {title}
        </Typography>
      </ListItemButton>
    </ListItem>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': closedMixin(theme),
  variants: [
    {
      props: ({ open }) => open,
      style: openedMixin(theme),
    },
    {
      props: ({ open }) => open,
      style: {
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
  ],
}));

const LogoImg = styled('img')`
  margin: 1rem;
  margin-top: 2rem;
  object-fit: cover;
  object-position: left;
`;
