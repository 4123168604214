import { ErrorMessage, Formik } from 'formik';
import FieldWrapper from '../../../components/molecules/FieldWrapper';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import InputError from '../../../components/atoms/InputError';
import * as Yup from 'yup';
import { CustomAction } from '../../../hooks/useFetchActions';

interface Props {
  initialValues: CustomAction;
  onNext: (v: CustomAction) => void;
  onPrev: () => void;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .required('This field cannot be empty.')
    .max(255, 'Name cannot be more than 255 characters.'),
  description: Yup.string().required('This field cannot be empty.'),
  user_message: Yup.string()
    .nullable()
    .max(255, 'This field cannot be more than 255 characters.'),
});

export default function PurposeStep({ initialValues, onNext, onPrev }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onNext}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper title="Name">
            <TextField
              placeholder="Enter the name of your tool (e.g. getUserInfo, lookupAddress...)"
              fullWidth
              InputProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '50px',
                },
              }}
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            <InputError>
              <ErrorMessage name="name" />
            </InputError>
          </FieldWrapper>
          <FieldWrapper title="Description">
            <TextField
              placeholder="Describe the Tool, its purpose, its use, etc."
              fullWidth
              multiline
              InputProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '18px',
                },
              }}
              {...formik.getFieldProps('description')}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
            />
            <InputError>
              <ErrorMessage name="description" />
            </InputError>
          </FieldWrapper>
          <FieldWrapper title="User Message">
            <TextField
              placeholder="Loading message for the user while the API is called"
              fullWidth
              multiline
              InputProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '18px',
                },
              }}
              {...formik.getFieldProps('user_message')}
              error={
                formik.touched.user_message &&
                Boolean(formik.errors.user_message)
              }
            />
            <InputError>
              <ErrorMessage name="user_message" />
            </InputError>
          </FieldWrapper>
          <Box
            display="flex"
            gap={2}
            sx={{ mt: 4, mb: 5.5 }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={onPrev}
              sx={(theme) => ({
                py: 1.5,
                backgroundColor: theme.palette.grey[300],
                color: theme.palette.grey[700],
                '&:hover': {
                  backgroundColor: theme.palette.grey[500],
                },
              })}
            >
              Previous
            </Button>
            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={formik.isSubmitting}
              sx={{ py: 1.5 }}
            >
              {formik.isSubmitting ? <CircularProgress size={20} /> : 'Save'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
}
