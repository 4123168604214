import { Typography } from '@mui/material';

const InputError = ({ children }: { children: JSX.Element }) => {
  return (
    <Typography
      mt={1}
      color="error.main"
      variant="caption"
    >
      {children}
    </Typography>
  );
};

export default InputError;
