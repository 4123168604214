import { ReactNode, useEffect, useRef, useState } from 'react';
import { Box, ClickAwayListener, styled } from '@mui/material';
import SimpleItem from './SimpleItem';
import ArrowDownIcon from './arrow-down.svg';
import { createPortal } from 'react-dom';

interface Item {
  title: string;
  value: string | number;
}

export interface SelectEvent {
  type: 'change';
  target: {
    name: string;
    value: string | number;
  };
}

interface IItemSelectorProps {
  value: string;
  items: Item[] | undefined;
  sx?: { minWidth?: string; mt?: number };
  onChange: (event: SelectEvent) => void;
  disabled?: boolean;
  name: string;
  error?: boolean;
}

const itemRenderer = (props: {
  title: string;
  value: string | number;
  onClick: (v: Item) => void;
  active?: true;
}) => (
  <SimpleItem
    key={props.value}
    {...props}
  />
);

export default function ItemSelector({
  value,
  items,
  sx,
  onChange,
  disabled = false,
  name,
  error,
}: IItemSelectorProps) {
  const ref = useRef<HTMLDivElement>();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (disabled) return;
    setOpen(true);
  };

  const onItemClick = (value: Item) => {
    onChange({ type: 'change', target: { name, value: value.value } });
    setOpen(false);
  };

  const [position, setPosition] = useState({} as DOMRect);
  useEffect(() => {
    setPosition(ref.current?.getClientRects()[0] as DOMRect);
  }, []);

  const valueObj = items?.find((item) => item.value === value);

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box
        ref={ref}
        onClick={onClick}
        sx={[
          {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '50px',
            cursor: 'pointer',
            height: '3rem',
            position: 'relative',
          },
          open
            ? (theme) => ({
                border: `2px solid ${theme.palette.primary.main}`,
              })
            : (theme) => ({
                border: error
                  ? `1px solid ${theme.palette.error.main}`
                  : `1px solid ${theme.palette.grey[500]}`,
              }),
          open
            ? {
                pl: '11px',
              }
            : {
                pl: '12px',
              },
          open
            ? {
                pr: '23px',
              }
            : {
                pr: '24px',
              },
          open
            ? {
                py: '7px',
              }
            : {
                py: '8px',
              },
          disabled
            ? (theme) => ({
                background: theme.palette.grey[100],
                cursor: 'not-allowed',
              })
            : {},
          sx || {},
        ]}
      >
        {valueObj && itemRenderer({ ...valueObj, onClick, active: true })}
        <ArrowDownIcon />
        <Menu
          position={position}
          open={open}
          onClose={() => setOpen(false)}
        >
          {items?.map((item) =>
            itemRenderer({ ...item, onClick: onItemClick })
          )}
        </Menu>
      </Box>
    </ClickAwayListener>
  );
}

interface MenuProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  position: DOMRect;
}

function Menu({ open, position, children }: MenuProps) {
  return createPortal(
    <MenuBox
      sx={[
        {
          top: position.y + position.height + 10,
          left: position.x,
          width: position.width,
        },
        open
          ? {
              display: 'block',
            }
          : {
              display: 'none',
            },
      ]}
    >
      {children}
    </MenuBox>,
    document.body
  );
}

const MenuBox = styled('div')(({ theme }) => ({
  position: 'absolute',
  // top: 'calc(100% + 10px)',
  // left: 0,
  // right: 0,
  zIndex: theme.zIndex.tooltip,
  background: theme.palette.common.white,
  border: `1px solid ${theme.palette.grey[500]}`,
  overflow: 'hidden',
  borderRadius: '10px',
  '& > *': {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));
