import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import FieldWrapper from '../molecules/FieldWrapper';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import InputError from '../../components/atoms/InputError';

const schema = Yup.object().shape({
  name: Yup.string().required('This field is required.'),
  phone: Yup.string()
    .required('This field is required.')
    .max(13, 'This field cannot be more than 13 characters.')
    .matches(/^[0-9]*$/, 'Phone number is not valid.'),
});

export interface Contact {
  id?: number;
  name: string;
  phone: string;
  country_code: string;
  updated_at?: string;
}

interface Props {
  initialValues: Contact;
  handleSubmit: (d: Contact) => void;
}

export default function ContactForm({ initialValues, handleSubmit }: Props) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper title="Name">
            <TextField
              placeholder="Enter your name"
              fullWidth
              InputProps={{
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '50px',
                },
              }}
              {...formik.getFieldProps('name')}
              error={formik.touched.name && Boolean(formik.errors.name)}
            />
            <InputError>
              <ErrorMessage name="name" />
            </InputError>
          </FieldWrapper>
          {/* <FieldWrapper title="Last Name">
              <TextField
                placeholder="Enter your last name"
                fullWidth
                InputProps={{
                  sx: {
                    fontWeight: 500,
                    fontSize: 12,
                    borderRadius: '50px',
                  },
                }}
                {...formik.getFieldProps('last_name')}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
              />
              <InputError>
                <ErrorMessage name="last_name" />
              </InputError>
            </FieldWrapper> */}
          <Box
            display="flex"
            gap={2}
          >
            <FieldWrapper
              title="Country Code"
              sx={{ flex: 1 }}
            >
              <TextField
                placeholder="1"
                fullWidth
                InputProps={{
                  startAdornment: '+',
                  sx: {
                    fontWeight: 500,
                    fontSize: 12,
                    borderRadius: '50px',
                  },
                }}
                {...formik.getFieldProps('country_code')}
                error={
                  formik.touched.country_code &&
                  Boolean(formik.errors.country_code)
                }
              />
              <InputError>
                <ErrorMessage name="country_code" />
              </InputError>
            </FieldWrapper>
            <FieldWrapper
              title="Phone Number"
              sx={{ flex: 3 }}
            >
              <TextField
                placeholder="Phone Number"
                fullWidth
                InputProps={{
                  sx: {
                    fontWeight: 500,
                    fontSize: 12,
                    borderRadius: '50px',
                  },
                }}
                {...formik.getFieldProps('phone')}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
              <InputError>
                <ErrorMessage name="phone" />
              </InputError>
            </FieldWrapper>
          </Box>

          <Button
            sx={{ mt: 2.5, mb: 1.5, py: 1.5 }}
            fullWidth
            variant="contained"
            type="submit"
            disabled={formik.isSubmitting}
          >
            {formik.isSubmitting ? <CircularProgress size={24} /> : 'Confirm'}
          </Button>
        </form>
      )}
    </Formik>
  );
}
