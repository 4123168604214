import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import EditIcon from '../../components/icons/Edit.svg';
import DeleteIcon from '../../components/icons/delete.svg';

import { useState } from 'react';
import httpAgent from '../../httpAgent';
import useSnackbar from '../../components/Snackbar';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmDeleteDialog from './ConfirmDeleteDialog';
import EditContactDialog from './EditContactDialog';
import { Contact } from '../../components/organs/ContactForm';

interface Props {
  data: Contact[];
}

export default function ContactsTable({ data }: Props) {
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '70%' }}>Name</TableCell>
            <TableCell align="left">Last Edited</TableCell>
            <TableCell align="center">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((data) => (
            <TableRow
              key={data.id!}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
              >
                {data.name || (
                  <Typography
                    fontSize={14}
                    color="grey.700"
                  >
                    Empty
                  </Typography>
                )}
              </TableCell>
              <TableCell align="left">
                {new Date(data.updated_at!).toLocaleString()}
              </TableCell>
              <TableCell align="center">
                <ActionsCell data={data} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
interface ActionsCellProps {
  data: Contact;
}
function ActionsCell({ data }: ActionsCellProps) {
  const [edit, setEdit] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const qc = useQueryClient();
  const showSnackbar = useSnackbar();
  const handleSave = async (data: Contact) => {
    try {
      const response = await httpAgent.put(
        'contacts/',
        JSON.stringify({ action: 'API', ...data })
      );
      if (response.status !== 200) {
        showSnackbar('Something went wrong', 'error');
        return;
      }
      setEdit(false);
      qc.invalidateQueries({ queryKey: ['contacts'] });
      showSnackbar('Custom action updated.');
    } catch (e) {
      Sentry.captureException(e);
    }
  };
  return (
    <Box
      display="flex"
      justifyContent="center"
      gap={2}
    >
      <ActionButton onClick={() => setEdit(true)}>
        <EditIcon />
        <Typography
          fontSize={14}
          fontWeight={400}
        >
          Edit
        </Typography>
      </ActionButton>
      <VerticalDivider />
      <ActionButton onClick={() => setDeleteDialog(true)}>
        <DeleteIcon />
        <Typography
          fontSize={14}
          fontWeight={400}
        >
          Delete
        </Typography>
      </ActionButton>
      <EditContactDialog
        initialValues={data}
        onClose={() => setEdit(false)}
        open={edit}
        onSave={handleSave}
      />
      <ConfirmDeleteDialog
        id={data.id!}
        onClose={() => setDeleteDialog(false)}
        open={deleteDialog}
      />
    </Box>
  );
}
const VerticalDivider = styled('div')(({ theme }) => ({
  flex: '0 0 1.6px',
  background: theme.palette.grey[500],
}));
const ActionButton = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  cursor: 'pointer',
}));
