import FieldWrapper from '../../../components/molecules/FieldWrapper';
import CheckDefault from './check-default.svg';
import CheckActive from './check-active.svg';
import * as Yup from 'yup';
import { ErrorMessage, Formik } from 'formik';
import InputError from '../../../components/atoms/InputError';
import { Button, TextField } from '@mui/material';
import ItemSelector from '../../../components/atoms/ItemSelector';
import { CustomAction } from '../../../hooks/useFetchActions';

interface ServerUrlProps {
  initialValues: CustomAction;
  onNext: (v: CustomAction) => void;
}

const schema = Yup.object().shape({
  url: Yup.string()
    .required('Please provide the server URL.')
    .url('URL is not valid'),
  token: Yup.string()
    .nullable()
    .max(255, 'Secret Token should be less than 255 characters.'),
});

const items = [
  { title: 'POST', value: 'POST' },
  { title: 'GET', value: 'GET' },
  { title: 'PUT', value: 'PUT' },
  { title: 'DELETE', value: 'DELETE' },
  { title: 'PATCH', value: 'PATCH' },
];

export default function ServerUrlStep({
  initialValues,
  onNext,
}: ServerUrlProps) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onNext}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit}>
          <FieldWrapper title="API Method">
            <ItemSelector
              items={items}
              {...formik.getFieldProps('method')}
            />
          </FieldWrapper>
          <FieldWrapper title="Server URL">
            <TextField
              placeholder="Server URL for your custom POST endpoint"
              fullWidth
              InputProps={{
                autoComplete: 'off',
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '50px',
                  px: 1.5,
                },
                endAdornment: formik.errors.url ? (
                  <CheckActive />
                ) : (
                  <CheckDefault />
                ),
              }}
              {...formik.getFieldProps('url')}
              error={formik.touched.url && Boolean(formik.errors.url)}
            />
            <InputError>
              <ErrorMessage name="url" />
            </InputError>
          </FieldWrapper>
          <FieldWrapper
            title="Server URL Secret Token"
            optional
          >
            <TextField
              placeholder="(Leave empty for unchanged)"
              fullWidth
              type="password"
              InputProps={{
                autoComplete: 'off',
                sx: {
                  fontWeight: 500,
                  fontSize: 12,
                  borderRadius: '50px',
                  px: 1.5,
                },
              }}
              {...formik.getFieldProps('token')}
              error={formik.touched.token && Boolean(formik.errors.token)}
            />
            <InputError>
              <ErrorMessage name="token" />
            </InputError>
          </FieldWrapper>
          <Button
            sx={{ mt: 1.5, mb: 5.5, py: 1.5 }}
            fullWidth
            variant="contained"
            type="submit"
          >
            Next
          </Button>
        </form>
      )}
    </Formik>
  );
}
